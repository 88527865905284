import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import './DocumentoDetalle.css';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';

const DocumentoDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
       
        setEstado(newEstado);

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(props.history.location.state.documento);

    }, []);

    const getDocumentoDetalle = async (doc) => {
        setDocumento({
            ...documento,
            loading: true
        });
        
        const retenciones = await httpGet('/api/v1/egresos/documento/retenciones/' + doc.factRowid);
        const detalles = await httpGet('/api/v1/egresos/documento/detalle/' + doc.factRowid);

        if((detalles.data !== null) && (detalles.data !== undefined)){
            setDocumento({
                ...documento,
                detalles: detalles.data,
                retenciones: retenciones.data,
                loading: false
            });
        }
        
    }

    const generarPdf = async (e, doc) => {
        e.preventDefault();

        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        
        const data = {
            empresa: estado.empresa,
            proveedor: proveedor,
            encabezado: props.history.location.state.documento,
            documento: documento
        }
        
        props.history.push({ pathname: '/portal/nota-pdf', state: {documento: data} });
    }

    const [documento, setDocumento ] = useState(
        {
            detalles: [],
            retenciones: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0,
                totalBase: 0
            },
            loading: false,
            error: {},
            reg: 0
        }
    );

    return(
        <div className='page-container'>
            <Title>Documento de proveedor - Detalle</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                
                <SubTitle>Detalle del documento</SubTitle>
                { documento.loading ?
                    <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                    </div>
                :
                    <>
                    <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>CO</th>
                            <th>FECHA</th>
                            <th>DOC. CRUCE</th>
                            <th>DETALLE</th>
                            <th>DESCUENTOS</th>
                            <th>RETENCIONES</th>
                            <th>VALOR FACTURA</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ props.history.location.state.documento.docIdCoMov + ' - ' + props.history.location.state.documento.docCoDescripcion }</td>
                            <td>{ formatDateMin(new Date(props.history.location.state.documento.factFecha)) }</td>
                            <td>{ props.history.location.state.documento.docCruTipoDoc + ' ' + props.history.location.state.documento.docCruNumeroDoc }</td>
                            <td>{ props.history.location.state.documento.dcpNotas }</td>
                            <td>${ formatNumber(props.history.location.state.documento.factDscto) }</td>
                            <td>${ formatNumber(props.history.location.state.documento.factValorRetencion) }</td>
                            <td>${ formatNumber(props.history.location.state.documento.docValorNeto) }</td>
                            <td><div className='pdf-icon-container' onClick={generarPdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Retenciones</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CODIGO CUENTA</th>
                            <th>DESCRIPCION CUENTA</th>
                            <th>VALOR RETENCIÓN</th>
                            <th>BASE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documento.retenciones.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ detalle.cuenta }</td>
                                            <td>{ detalle.cuentaDescripcion }</td>
                                            <td>${ formatNumber(detalle.valorCr - detalle.valorDb) }</td>
                                            <td>${ formatNumber(detalle.valorBase) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td>TOTAL:</td>
                            <td>${ formatNumber(documento.retenciones.totalCredito - documento.retenciones.totalDebito) }</td>
                            <td>${ formatNumber(documento.retenciones.totalBase) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />

                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>ITEM</th>
                            <th>DESCRIPCION</th>
                            <th>CANTIDAD</th>
                            <th>UNIDAD DE MEDIDA</th>
                            <th>VALOR BRUTO</th>
                            <th>DESCUENTO LINEA</th>
                            <th>DESCUENTO GLOBAL</th>
                            {/*<th>PORCENTAJE IMPUESTOS</th>*/}
                            <th>VALOR IMPUESTOS</th>
                            <th>TOTAL</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documento.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ detalle.itemId }</td>
                                            <td>{ detalle.itemDescripcion }</td>
                                            <td>{ detalle.invCant }</td>
                                            <td>{ detalle.invIdUM }</td>
                                            <td>${ formatNumber(detalle.invValorBruto) }</td>
                                            <td>${ formatNumber(detalle.invDsctoLinea) }</td>
                                            <td>${ formatNumber(detalle.invDsctoGlobal) }</td>
                                            {/*<td>{ Math.floor(formatNumber( (detalle.invValorImpuestos * 100) / (detalle.invValorBruto - ( detalle.invDsctoLinea + detalle.invDsctoGlobal )))) }%</td>*/}
                                            <td>${ formatNumber(detalle.invValorImpuestos) }</td>
                                            <td>${ formatNumber(detalle.invValorNeto) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                    </table>
                    </>
                }
            </div>
        </div>
    );
}

export default DocumentoDetalle
