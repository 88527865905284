import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { formatNumber } from '../../misc';
import { Link } from 'react-router-dom';
import './ReteIVADetalle.css';

const ReteIVADetalle = (props) => {

    const { history: { location: { state: { periodo } } } } = props;

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

       
        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);
        getReteIva();

        

    }, []);

    const getReteIva = async () => {

        setRetencion({
            ...retencion,
            loading: true
        });

        console.log('PERIODO:');
        console.log(periodo);

        const estado2 = JSON.parse(sessionStorage.getItem('estado'));
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const tercero = await httpGet('/ProveedoresWSNEW/VerTercero?tercero=' + proveedor.nit);
        const certificado = await httpGet('/ProveedoresWSNEW/ReteIvaDetalle?emp=CA&nit=' + proveedor.nit + '&lapsoInicial=' + periodo.lapsoInicial + '&lapsoFinal=' + periodo.lapsoFinal);

        console.log('CERTIFICADO');
        console.log(certificado);
        
        let totalBase = 0;
        let totalRetencion = 0;

        for( const reg of certificado.data){
            totalBase = totalBase + reg.base;
            totalRetencion = totalRetencion + reg.retencion;
        }

        let total = {
            base: totalBase,
            retencion: totalRetencion
        }

        if((certificado !== null) && (certificado !== undefined)){
            setRetencion({
                ...retencion,
                empresa: estado2.empresa,
                proveedor: tercero.data,
                data: certificado.data,
                total: total,
                anno: '2020',
                reteIva: true,
                periodo: periodo,
                loading: false
            });
        }
        
    }

    const [retencion, setRetencion ] = useState(
        {
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [
            ],
            total: {
                base: '',
                retencion: ''
            },
            tipo: {
                titulo: 'Certificado de retencion I.V.A.'
            },
            anno: '2020',
            loading: false,
            error: {}
        }
    );

    return(
        <div className='page-container'>
            <Title>Certificado de retención de I.V.A.</Title>
            <div className='page-body'>
                <SubTitle>Certificado de retención de I.V.A. SUPER MARKET S.A. </SubTitle>
                <div>
                    <div>SUPER MARKET S.A.</div>
                    <div>999.999.999-9</div>
                </div>
                <br />
                <div>
                    CALI
                </div>
                <br />
                <SubTitle>Periodo gravable: { periodo.descripcion } { retencion.anno }</SubTitle>
                <div>
                    <div><span className='info-title'>Retenido: </span><span>{ retencion.proveedor.descripcion }</span></div>
                    <div><span className='info-title'>N.I.T.: </span><span>{ retencion.proveedor.codigo }</span></div>
                    <div><span className='info-title'>Dirección </span><span>{ retencion.proveedor.direccion }</span></div>
                    <div><span className='info-title'>Ciudad </span><span>{ retencion.proveedor.ciudad }</span></div>
                    <br />
                    <br />
                    <div><span className='info-title'>Ciudad donde se practicó la retención: </span><span>{ estado.empresa.ciudad }</span></div>
                    <div><span className='info-title'>Ciudad donde se consignó la retención: </span><span>{ estado.empresa.ciudad }</span></div>
                </div>
                <br />
                <table className='info-table tabla-retencion'>
                    <thead>
                        <tr>
                            <th>CONCEPTO</th>
                            <th>TASA</th>
                            <th>BASE</th>
                            <th>RETENCION</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            retencion.data.map(
                                rete => {
                                    return(
                                        <tr key={rete.id}>
                                            <td>{ rete.descripcionCuenta }</td>
                                            <td>{ rete.tasa }%</td>
                                            <td>${ formatNumber(rete.base * -1) }</td>
                                            <td>${ formatNumber(rete.retencion) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td colSpan='2'>TOTAL</td>
                            <td>${ formatNumber(retencion.total.base) }</td>
                            <td>${ formatNumber(retencion.total.retencion) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <div>
                    <div className='retencion-footer-text'>Fecha de expedición 13 / Mar / 2021</div>
                    < br/>
                    <div>
                        <span>Descargar </span>
                        <span className='pdf-icon-container'><Link to={ { pathname: 'certificado-pdf', state: { retencion: retencion } } }><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></span>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default ReteIVADetalle;
