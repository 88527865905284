import React, { useState, useEffect } from 'react';
import { Page, Text, View, Document, StyleSheet } from '@react-pdf/renderer';
import ReactPDF, { PDFDownloadLink, PDFViewer, PDFRenderer } from '@react-pdf/renderer/dist/react-pdf.es';
import Button from '../../components/button/Button';
import { formatNumber, formatDateMin} from '../../misc/index';

const Nota = (props) => {

    const [ totales, setTotales ] = useState({
        totalCantidad: 0,
        totalValorBruto: 0,
        totalDsctoLinea: 0,
        totalDsctoGlobal: 0,
        totalValorImpuestos: 0,
        totalValorNeto: 0
    });

    useEffect(() => {
        let totalCantidad = 0;
        let totalValorBruto = 0;
        let totalDsctoLinea = 0;
        let totalDsctoGlobal = 0;
        let totalValorImpuestos = 0;
        let totalValorNeto = 0;
        for(const detalle of props.documento.documento.detalles){
            totalCantidad = totalCantidad + detalle.invCant;
            totalValorBruto = totalValorBruto + detalle.invValorBruto;
            totalDsctoLinea = totalDsctoLinea + detalle.invDsctoLinea;
            totalDsctoGlobal = totalDsctoGlobal + detalle.invDsctoGlobal;
            totalValorImpuestos = totalValorImpuestos + detalle.invValorImpuestos;
            totalValorNeto = totalValorNeto + detalle.invValorNeto;
        }

        setTotales({
            ...totales,
            totalCantidad: totalCantidad,
            totalValorBruto: totalValorBruto,
            totalDsctoLinea: totalDsctoLinea,
            totalDsctoGlobal: totalDsctoGlobal,
            totalValorImpuestos: totalValorImpuestos,
            totalValorNeto: totalValorNeto
        });
    }, []);

    return(
        <Document>
            <Page size="LETTER" orientation="landscape" style={styles.pagina}>
                <View style={styles.header}>
                    <Text style={styles.title}>{props.documento.empresa.razonSocial}</Text>
                    <Text style={styles.subTitle}>DOCUMENTO PROVEEDOR</Text>
                </View>
                <View style={styles.proveedorContainer}>
                    <Text>N.I.T.: { props.documento.proveedor.nit }</Text>
                    <Text>PROVEEDOR: { props.documento.proveedor.razonSocial }</Text>
                </View>
                <View style={styles.bodyContainer}>
                    <View style={styles.encabezadoContainer}>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Centro de operacion: </Text>
                            <Text>{ props.documento.encabezado.docIdCoMov + ' - ' + props.documento.encabezado.docCoDescripcion }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Documento cruce: </Text>
                            <Text>{ props.documento.encabezado.docCruTipoDoc + ' ' + props.documento.encabezado.docCruNumeroDoc }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Fecha: </Text>
                            <Text>{ formatDateMin(new Date(props.documento.encabezado.factFecha)) }</Text>
                        </View>
                        <View style={styles.campoContainer}>
                            <Text style={styles.campoTitulo}>Detalle: </Text>
                            <Text>{ props.documento.encabezado.notas }</Text>
                        </View>
                    </View>
                    
                    <Text style={styles.campoTitulo}>RETENCIONES:</Text>
                    <View style={styles.detalleContainer}>
                        <View style={styles.tabla}>
                            <View style={styles.th}>
                                <Text style={[styles.tablaCampoTitulo, {width: '5%'}]}>NO.</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: '10%'}]}>CUENTA</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: '55%'}]}>DESCRIPCIÓN CUENTA</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: '15%'}]}>VALOR RETENCIÓN</Text>
                                <Text style={[styles.tablaCampoTitulo, {width: '15%'}]}>BASE</Text>
                            </View>
                            {
                                props.documento.documento.retenciones.detalles.map(
                                    (detalle, index) => {
                                        console.log(detalle);
                                        return(
                                            <View key={index} style={styles.tr}>
                                                <Text style={[styles.tablaCampo, {width: '5%'}]}>{ index + 1 }</Text>
                                                <Text style={[styles.tablaCampo, {width: '10%'}]}>{ detalle.cuenta }</Text>
                                                <Text style={[styles.tablaCampo, {width: '55%'}]}>{ detalle.cuentaDescripcion }</Text>
                                                <Text style={[styles.tablaCampo, {width: '15%'}]}>${ formatNumber(detalle.valorCr - detalle.valorDb) }</Text>
                                                <Text style={[styles.tablaCampo, {width: '15%'}]}>${ formatNumber(detalle.valorBase) }</Text>
                                            </View>
                                        );
                                    }
                                )
                            }
                            <View style={styles.tr}>
                                <Text style={[styles.tablaCampoTotal, {width: '70%'}]}>TOTAL:</Text>
                                <Text style={[styles.tablaCampo, {width: '15%'}]}>${ formatNumber(props.documento.documento.retenciones.totalCredito - props.documento.documento.retenciones.totalDebito) }</Text>
                                <Text style={[styles.tablaCampo, {width: '15%'}]}>${ formatNumber(props.documento.documento.retenciones.totalBase) }</Text>
                            </View>
                        </View>
                    </View>
                </View>

                <Text style={[styles.campoTitulo, , { marginTop: 20 }]}>DETALLES:</Text>
                <View style={styles.detalleContainer}>
                    <View style={styles.tabla}>
                        <View style={styles.th}>
                            <Text style={[styles.tablaCampoTitulo, {width: 50}]}>ITEM</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 150}]}>DESCRIPCION</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 50}]}>U. M.</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 60}]}>CANTIDAD</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 80}]}>VALOR BRUTO</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 80}]}>DESCUENTO LINEA</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 80}]}>DESCUENTO GLOBAL</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 80}]}>IMPUESTOS</Text>
                            <Text style={[styles.tablaCampoTitulo, {width: 80}]}>TOTAL</Text>
                        </View>
                        {
                            props.documento.documento.detalles.map(
                                (detalle, index) => {
                                    console.log(detalle);
                                    return(
                                        <View key={index} style={styles.tr}>
                                            <Text style={[styles.tablaCampo, {width: 50}]}>{ detalle.itemId }</Text>
                                            <Text style={[styles.tablaCampo, {width: 150}]}>{ detalle.itemDescripcion }</Text>
                                            <Text style={[styles.tablaCampo, {width: 50}]}>{ detalle.invIdUM }</Text>
                                            <Text style={[styles.tablaCampo, {width: 60}]}>{ detalle.invCant }</Text>
                                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(detalle.invValorBruto) }</Text>
                                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(detalle.invDsctoLinea) }</Text>
                                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(detalle.invDsctoGlobal) }</Text>
                                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(detalle.invValorImpuestos) }</Text>
                                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(detalle.invValorNeto) }</Text>
                                        </View>
                                    );
                                }
                            )
                        }
                        <View style={styles.tr}>
                            <Text style={[styles.tablaCampoTotal, {width: 250}]}>TOTAL:</Text>
                            <Text style={[styles.tablaCampo, {width: 60}]}>{ totales.totalCantidad }</Text>
                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(totales.totalValorBruto) }</Text>
                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(totales.totalDsctoLinea) }</Text>
                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(totales.totalDsctoGlobal) }</Text>
                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(totales.totalValorImpuestos) }</Text>
                            <Text style={[styles.tablaCampo, {width: 80}]}>${ formatNumber(totales.totalValorNeto) }</Text>
                        </View>
                    </View>
                </View>
                <View style={styles.pageNumber}>
                    <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (`${pageNumber} / ${totalPages}`)} fixed />
                </View>
            </Page>
        </Document>
    );
}

const NotaPDF = (props) => {

    useEffect(() => {

        if(!props.history.location.state.documento){
            props.history.push('/portal');
        }

    }, []);

    return(
        <>
            <div>
                <Button onClick={ () => { props.history.goBack() } } >Volver</Button>
            </div>
            <br />
            <PDFViewer style={{ width: '100%', height: '100vh' }}>
                <Nota documento={props.history.location.state.documento} />
            </PDFViewer>
        </>
    );
}

const styles = StyleSheet.create({
    header: {
        alignItems: 'center',
        marginBottom: 50
    },
    title: {
        fontSize: 20,
        marginBottom: 10    
    },
    subTitle: {
        fontSize: 20,    
    },
    proveedorContainer: {
        fontSize: 10,
        marginBottom: 20
    },
    bodyContainer: {
        
    },
    encabezadoContainer: {
        fontSize: 10,
        marginBottom: 10
    },
    campoContainer: {
        flexDirection: 'row',
    },
    campoTitulo: {
        fontSize: 10
    },
    tabla: {
        /*borderWidth: 1,*/
        borderRadius: 5,
        fontSize: 10
    },
    th: {
        flexDirection: 'row',
    },
    tr: {
        flexDirection: 'row',
    },
    tablaCampoTitulo: {
        backgroundColor: 'black',
        color: 'white',
        width: 64,
        borderRightWidth: 1,
        borderRightColor: 'white',
        paddingLeft: 5
        
    },
    tablaCampo: {
        width: 64,
        color: 'black',
        borderRightWidth: 1,
        borderRightColor: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    tablaCampoTotal: {
        width: 64,
        color: 'black',
        paddingLeft: 5,
        borderBottomWidth: 1
    },
    pagina: {
        padding: 50
    },
    pageNumber: {
        position: 'absolute',
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: 'center',
        color: 'grey',
    }
});

export default NotaPDF;