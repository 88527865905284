import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import SubTitle from '../../components/subTitle/SubTitle';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { formatNumber, getYear, getCurrentDate } from '../../misc';
import './Retencion.css';
import { Link } from 'react-router-dom';
import { Alert, Spinner } from 'react-bootstrap';
import Button from '../../components/button/Button';

const Fecha = (props) => {
    return(
        <div className='fecha-container'>
            <div>{props.label}</div>
            <input type="date" id="fecha" name={props.name} value={props.value} onChange={props.onChange} />
        </div>
    );
}

const Retencion = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

       
        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);       

        const type = props.history.location.state.type;
        const tipo = setType();
        const estado2 = JSON.parse(sessionStorage.getItem('estado'));
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        
        setRetencion({
            ...retencion,
            empresa: estado2.empresa,
            tipo: tipo,
            reteIva: false,
        });
        
        if(type !== 'RETEIVA'){
        } else {
            //getReteIVA();
        }
        
    }, []);

    const getReteFuente = async () => {
        setRetencion({
            ...retencion,
            loading: true,
            tipo: {
                ...retencion.tipo,
                visible: true,
            }
        });

        console.log("URL:");
        console.log(retencion.tipo.url);

        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const certificado = await httpGet(retencion.tipo.url  + '&fecha1=' + retencion.tipo.fechaDesde + "&fecha2=" + retencion.tipo.fechaHasta);

        console.log('CERTIFICADO');
        console.log(certificado);
        
        let totalBase = 0;
        let totalRetencion = 0;

        if(certificado.data.length > 0){
            const pr = {
                nit: certificado.data[0]['nit'],
                razonSocial: certificado.data[0]['razonSocial'],
                direccion: certificado.data[0]['direccion'],
                ciudad: certificado.data[0]['ciudad']
            }
    
            for( const reg of certificado.data){
                totalBase = totalBase + reg.base;
                totalRetencion = totalRetencion + reg.valorRetencion;
            }
    
            let total = {
                base: totalBase,
                retencion: totalRetencion
            }
    
            if((certificado !== null) && (certificado !== undefined)){
                const type = props.history.location.state.type;
                setRetencion({
                    ...retencion,
                    empresa: estado.empresa,
                    proveedor: pr,
                    data: certificado.data,
                    total: total,
                    loading: false,                
                    tipo: {
                        ...retencion.tipo,
                        periodoGravable: 'Periodo gravable del: ' + retencion.tipo.fechaDesde + ' al: ' + retencion.tipo.fechaHasta,
                        visible: true,
                    }
                });
            }
        } else {
            setRetencion({
                ...retencion,
                loading: false,                
                showNoDataMsg: true,
                tipo: {
                    ...retencion.tipo,
                    visible: true
                }
            });
        }
        
    }

    const [retencion, setRetencion ] = useState(
        {
            proveedor: {
                codigo: '',
                deigitoVerificacion: '',
                descripcion: '',
                direccion: '',
                ciudad: ''
            },
            data: [],
            total: {
                base: 0,
                retencion: 0
            },
            tipo: {
                codigo: '',
                titulo: '',
                periodoGravable: '',
                ciudad: '',
                fechaDesde: '',
                fechaHasta: '',
                visible: false
            },
            loading: false,
            showNoDataMsg: false,
            error: {}
        }
    );

    const setType = () => {
        const type = props.history.location.state.type;
        let tipo = {};
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        const estado2 = JSON.parse(sessionStorage.getItem('estado'));

        switch(type){
            case 'RETEFUENTE':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención en la fuente',
                    periodoGravable: 'Año gravable: ' + getYear(1),
                    url: '/api/v1/retenciones?nit=' + proveedor.nit + '&cuenta=2365%',
                    ciudad: estado2.empresa.ciudad,
                    fechaDesde: '',
                    fechaHasta: '',
                    visible: false
                }
                break;
            case 'RETEICA':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención Industria, Comercio y Avisos (I.C.A.)',
                    periodoGravable: 'Año gravable: ' + getYear(1) ,
                    url: '/api/v1/retenciones?nit=' + proveedor.nit + '&cuenta=' + props.history.location.state.ciudad.cuenta + '%',
                    ciudad: props.history.location.state.ciudad.descripcion,
                    fechaDesde: '',
                    fechaHasta: '',
                    visible: false
                }
                break;
            case 'RETEIVA':
                tipo = {
                    codigo: type,
                    titulo: 'Certificado de retención I.V.A.',
                    periodoGravable: 'Periodo gravable del: ' + retencion.tipo.fechaDesde + ' al: ' + retencion.tipo.fechaHasta,
                    url: '/api/v1/retenciones?nit=' + proveedor.nit + '&cuenta=2367%',
                    ciudad: estado2.empresa.ciudad,
                    visible: false
                }
                break;
        }

        return tipo;
    }

    const handleChange = (e) => {
        setRetencion({
            ...retencion,
            tipo: {
                ...retencion.tipo,
                [e.target.name]: e.target.value
            }
        });
    }

    const handleClick = () => {
        console.log("FECHA DESDE:")
        console.log(retencion.tipo.fechaDesde.replace(/-/g, ''));
        console.log("FECHA HASTA:")
        console.log(retencion.tipo.fechaHasta.replace(/-/g, ''));

        if((retencion.tipo.fechaDesde.length > 0) && (retencion.tipo.fechaHasta.length > 0)){
            getReteFuente();
        } else {
            alert("Por favor ingrese las fechas correctamente.")
        }
    }

    return(
        <div className='page-container'>
            <Title>{ retencion.tipo.titulo }</Title>
            
            <div className='page-body'>

                <br/>
                <>
                    <Alert variant='warning'>Señor proveedor tenga en cuenta que la información del mes actual y el mes anterior puede variar si aún no se ha hecho el cierre contable, si tiene dudas por favor comuniquese con el departamento contable.</Alert>
                    <div className='retencion-fechas-container'>
                        <Fecha label="Fecha inicial:" name='fechaDesde' value={retencion.tipo.fechaDesde} onChange={handleChange} /> 
                        <Fecha label="Fecha final:" name='fechaHasta' value={retencion.tipo.fechaHasta} onChange={handleChange} />
                        <Button onClick={handleClick}>Consultar</Button>
                    </div>
                </>
                               
                {retencion.tipo.visible &&
                <>
                {retencion.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                retencion.showNoDataMsg ?
                <>
                    <br />
                    <br />
                    <Alert variant='warning'>No se encontró información con los parametros de busqueda.</Alert>
                </>
                :
                <>
                <br/>
                <br/>
                <SubTitle>{ retencion.tipo.titulo } { estado.empresa.razonSocial }</SubTitle>
                <div>
                    <div>{ estado.empresa.razonSocial }</div>
                    <div>{ estado.empresa.nit }-{estado.empresa.digitoVerificacion}</div>
                </div>
                <br />
                <div>
                    { estado.empresa.ciudad }
                </div>
                <br />
                <SubTitle>{ retencion.tipo.periodoGravable }</SubTitle>
                <div>
                    <div><span className='info-title'>Retenido: </span><span>{ retencion.proveedor.razonSocial }</span></div>
                    <div><span className='info-title'>N.I.T.: </span><span>{ retencion.proveedor.nit }</span></div>
                    <div><span className='info-title'>Dirección </span><span>{ retencion.proveedor.direccion }</span></div>
                    <div><span className='info-title'>Ciudad </span><span>{ retencion.proveedor.ciudad }</span></div>
                    <br />
                    <br />
                    <div><span className='info-title'>Ciudad donde se practicó la retención: </span><span>{ retencion.tipo.ciudad }</span></div>
                    <div><span className='info-title'>Ciudad donde se consignó la retención: </span><span>{ retencion.tipo.ciudad }</span></div>
                </div>
                <br />
                <table className='info-table tabla-retencion'>
                    <thead>
                        <tr>
                            <th>CONCEPTO</th>
                            <th>TASA</th>
                            <th>BASE</th>
                            <th>RETENCION</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            retencion.data.map(
                                rete => {
                                    return(
                                        <tr key={rete.id}>
                                            <td>{ rete.cuentaDescripcion }</td>
                                            <td>{ rete.tasa }%</td>
                                            <td>${ formatNumber(rete.base) }</td>
                                            <td>${ formatNumber(rete.valorRetencion) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td colSpan='2'>TOTAL</td>
                            <td>${ formatNumber(retencion.total.base) }</td>
                            <td>${ formatNumber(retencion.total.retencion) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <div>
                    <div className='retencion-footer-text'>Fecha de expedición { getCurrentDate() }</div>
                    < br/>
                    <div>
                        <span>Descargar </span>
                        <span className='pdf-icon-container'><Link style={{ color: 'red' }} to={{ pathname: '/portal/certificado-pdf', state: { retencion: retencion } }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></span>
                    </div>
                </div>
                </>
                }
                </>
                }
            </div>
            
        </div>
    );
}

export default Retencion;
