import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './EgresoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
 

const DocumentoFIDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    const [documento, setDocumento ] = useState(
        {
            encabezado: {
                saldoAbiertoRowid: '',
                docIdCoMov: '',
                docCruTipoDoc: '',
                docCruNumeroDoc: '',
                fechaDoc: new Date(),
                valor: 0,
                valorPagoAlt: 0
            },
            movimiento: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0
            }
        }
    );

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.documento){
            props.history.push('/portal');
            return 0;
        }
        
        getDocumentoDetalle(props.history.location.state.documento, newEstado.proveedor);

    }, []);

    const getDocumentoDetalle = async (doc, proveedor) => {
        setDocumento({
            ...documento,
            loading: true
        });
        
        const movimiento = await httpGet('/api/v1/egresos/detalle/' + doc.saldoAbiertoRowid);

        if((movimiento !== null) && (movimiento !== undefined)){
            setDocumento({
                ...documento,
                encabezado: doc,
                movimiento: movimiento.data,
                loading: false
            });
        }
        
    }
    
    const generarEgresoDetallePdf = async () => {

        let data = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: documento.encabezado,
            movimiento: documento.movimiento,
        }

        console.log(data);

        props.history.push({ pathname: '/portal/documento-fi-pdf', state: { documento: data } });
    }

    const documentoDetallePDF = async (doc) => {
        
        const retenciones = await httpGet('/api/v1/egresos/documento/retenciones/' + doc.factRowid);
        const detalles = await httpGet('/api/v1/egresos/documento/detalle/' + doc.factRowid);

        if((detalles.data !== null) && (detalles.data !== undefined)){

            const data = {
                empresa: estado.empresa,
                proveedor: JSON.parse(sessionStorage.getItem('proveedor')),
                encabezado: doc,
                documento: {
                    detalles: detalles.data,
                    retenciones: retenciones.data
                }
            }
            
            props.history.push({ pathname: '/portal/nota-pdf', state: {documento: data} });
        }

        
        
    }

    return(
        <div className='page-container'>
            <Title>Documento</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del documento</SubTitle>
                {documento.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CO</th>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            <th>DETALLE</th>
                            <th>VALOR</th>                                                        
                            <th>DEBITOS</th>                                                        
                            <th>CREDITOS</th>                                                        
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ documento.encabezado.docIdCoMov }</td>
                            <td>{ documento.encabezado.docCruTipoDoc + '-' + documento.encabezado.docCruNumeroDoc }</td>
                            <td>{ formatDateMin(new Date(documento.encabezado.fechaDoc)) }</td>
                            <td>{ documento.encabezado.notas }</td>
                            <td>${ formatNumber(documento.encabezado.valorPagoAlt) }</td>
                            <td>${ formatNumber(documento.movimiento.totalDebito) }</td>
                            <td>${ formatNumber(documento.movimiento.totalCredito) }</td>
                            <td><div className='pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Movimiento contable</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CODIGO CUENTA</th>
                            <th>DESCRIPCION CUENTA</th>
                            <th>CO</th>
                            <th>TERCERO</th>
                            <th>RAZON SOCIAL</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            documento.movimiento.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ detalle.cuentaId }</td>
                                            <td>{ detalle.cuentaDescripcion }</td>
                                            <td>{ detalle.idCo }</td>
                                            <td>{ detalle.nit }</td>
                                            <td>{ detalle.razonSocial }</td>
                                            <td>${ formatNumber(detalle.valorDb) }</td>
                                            <td>${ formatNumber(detalle.valorCr) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>SUMAS IGUALES</td>
                            <td>${ formatNumber(documento.movimiento.totalDebito) }</td>
                            <td>${ formatNumber(documento.movimiento.totalCredito) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                </>
                }
            </div>
        </div>
    );
}

export default DocumentoFIDetalle
