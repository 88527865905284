import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import { httpGet } from '../../http';
import { Spinner } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { formatNumber, formatDateMin } from '../../misc/index';
import './Notas.css';

const Notas = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.co){
            props.history.push('/portal');
            return 0;
        }

        getNotas(newEstado.proveedor.nit);

    }, []);

    const getNotas = async (tercero) => {
        setNotas({
            ...notas,
            loading: true
        });
        
        const np = await httpGet('/api/v1/egresos/notas/tercero?co=' + props.history.location.state.co.codigo + '&nit=' + tercero + '&pagina=0&cant=100');
        /*const co = await httpGet('/api/v1/centro-operacion/codigo?cod=' + props.history.location.state.co);*/

        if((np !== null) && (np !== undefined)){
            setNotas({
                ...notas,
                co: props.history.location.state.co,
                data: np.data,
                loading: false
            });
        }
        
    }

    const generarPdf = async (e, nota) => {
        e.preventDefault();

        const encabezado = await httpGet('/ProveedoresWSNEW/api/nota?co=' + nota.co + '&tipoDocumento=' + nota.tipoDocumento + '&numeroDocumento=' + nota.numeroDocumento);
        const detalles = await httpGet('/ProveedoresWSNEW/api/nota/detalle?co=' + nota.co + '&tipoDocumento=' + nota.tipoDocumento + '&numeroDocumento=' + nota.numeroDocumento);
        const co = await httpGet('/ProveedoresWSNEW/api/CentroOperacion/codigo?cod=' + nota.co);

        if((encabezado !== null) && (encabezado !== undefined)){
            let documento = {
                co: co.data,
                proveedor: estado.proveedor,
                encabezado: encabezado.data[0],
                detalles: detalles.data
            }

            props.history.push({ pathname: '/portal/nota-pdf', state: {documento: documento} });
        }
    }

    const [notas, setNotas ] = useState(
        {
            co: {
                codigo: '',
                nombre: ''
            },
            data: [],
            loading: false,
            error: {}
        }
    );

    return(
        <div className='page-container'>
            <Title>Notas de proveedor</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial } </SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{estado.proveedor.razonSocial }</span></div>
                    <div><span className='info-title'>Centro de operación </span><span>{ notas.co.codigo } - { notas.co.nombre }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Notas de proveedor</SubTitle>
                { notas.loading ?
                <div className='loading-spinner'>
                        <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>FECHA</th>
                            <th>TOTAL</th>
                            <th>DETALLE</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            notas.data.map(
                                (documento, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ documento.dcpTipoDoc + '-' + documento.dcpNumeroDoc }</td>
                                            <td>{ documento.docCruTipoDoc + documento.docCruNumeroDoc }</td>
                                            <td>{ formatDateMin(new Date(documento.factFecha)) }</td>
                                            <td>${ formatNumber(documento.valor) }</td>
                                            <td>{ documento.dcpNotas }</td>
                                            <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
        </div>
    );
}

export default Notas;
