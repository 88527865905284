import React, { useState, useEffect } from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import './DocumentosDetalle.css';
import { formatNumber } from '../../misc/index';

const DocumentosDetalle = (props) => {

    const { history: { location: { state: { detalle } } } } = props

    useEffect(() => {

        getDocumentosDetalle(props.history.location.state.egreso);

    }, []);

    const getDocumentosDetalle = async () => {
        setDocumentos({
            ...documentos,
            loading: true
        });
        
        console.log('PROPS');
        console.log(props);
        
        const docs = await httpGet('/ProveedoresWSNEW/api/nota/cc?co=' + detalle.coMovimiento + '&tipoDocumento=' + detalle.tipoDocumentoCruce + '&numeroDocumento=' + detalle.numeroDocumentoCruce);

        if((docs !== null) && (docs !== undefined)){
            setDocumentos({
                ...documentos,
                data: docs.data ,
                loading: false
            });
        }
        
    }

    const generarPdf = async (e, doc) => {
        e.preventDefault();

        const encabezado = await httpGet('/ProveedoresWSNEW/api/nota?co=' + doc.co + '&tipoDocumento=' + doc.tipoDocumento + '&numeroDocumento=' + doc.numeroDocumento);
        const detalles = await httpGet('/ProveedoresWSNEW/api/nota/detalle?co=' + doc.co + '&tipoDocumento=' + doc.tipoDocumento + '&numeroDocumento=' + doc.numeroDocumento);
        const co = await httpGet('/ProveedoresWSNEW/api/CentroOperacion/codigo?cod=' + doc.co);
        const proveedor = JSON.parse(sessionStorage.getItem('proveedor'));

        if((encabezado.data !== null) && (encabezado.data !== undefined)){

            console.log("ENCABEZADO.");
            console.log(encabezado);
            
            const doc2 = { 
                proveedor: proveedor, 
                co: co.data,
                encabezado: encabezado.data[0], 
                detalles: detalles.data
            };
            
            
            let documento = {
                proveedor: proveedor,
                encabezado: encabezado.data[0],
                detalles: detalles.data
            }

            props.history.push({ pathname: '/portal/nota-pdf', state: {documento: doc2} });
        }
    }

    const [documentos, setDocumentos ] = useState(
        {
            data: [],
            loading: false,
            error: {}
        }
    );

    return(
        <div className='page-container'>
            <Title>Documentos de proveedor</Title>
            <div className='page-body'>
                { documentos.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>DOCUMENTO</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>FECHA</th>
                            <th>TOTAL</th>
                            <th>DETALLE</th>
                            <th>VER</th>
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        { 
                            documentos.data.map(
                                documento => {
                                    return(
                                        <tr key={documento.id}>
                                            <td>{ documento.tipoDocumento + '-' + documento.numeroDocumento }</td>
                                            <td>{ documento.documentoProveedor }</td>
                                            <td>{ documento.fechaDoc }</td>
                                            <td>${ formatNumber(documento.totalNeto) }</td>
                                            <td>{ documento.detalle }</td>
                                            <td><div className='view-icon-container'><Link to={{ pathname: '/portal/documento-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div></td>
                                            <td><div className='pdf-icon-container'><Link style={{ color: 'red' }} onClick={(e) => { generarPdf(e, documento) }}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></Link></div></td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                }
            </div>
        </div>
    );
}

export default DocumentosDetalle;
