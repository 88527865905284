import React, { useState, useEffect} from 'react';
import Title from '../../components/title/Title';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-regular-svg-icons';
import { faFilePdf } from '@fortawesome/free-regular-svg-icons';
import SubTitle from '../../components/subTitle/SubTitle';
import './EgresoDetalle.css';
import { httpGet } from '../../http';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { formatNumber, formatDateMin } from '../../misc/index';
 

const EgresoDetalle = (props) => {

    const [ estado, setEstado ] = useState({
        empresa: {
            nit: '',
            razonSocial: '',
            nombreAlterno: ''
        },
        proveedor: {
            nit: '',
            razonSocial: '',
            tel: '',
            email: ''
        },
        loading: true,
        error: {}
    });

    useEffect(() => {

        console.log(JSON.parse(sessionStorage.getItem('estado')));

        let newEstado = JSON.parse(sessionStorage.getItem('estado'));
        newEstado.proveedor = JSON.parse(sessionStorage.getItem('proveedor'));
        console.log(newEstado);
        
        setEstado(newEstado);

        if(!props.history.location.state.egreso){
            props.history.push('/portal');
            return 0;
        }
        
        getEgresoDetalle(props.history.location.state.egreso, newEstado.proveedor);

    }, []);

    const getEgresoDetalle = async (egreso2, proveedor) => {
        setEgreso({
            ...egreso,
            loading: true
        });
        
        const movimiento = await httpGet('/api/v1/egresos/detalle/' + egreso2.rowid);
        const documentos = await httpGet('/api/v1/egresos/documentos/' + proveedor.nit + '/' + egreso2.rowid);
        const retenciones = await httpGet('/api/v1/egresos/retenciones/' + egreso2.rowid);

        if((movimiento !== null) && (movimiento !== undefined)){
            setEgreso({
                ...egreso,
                encabezado: egreso2,
                movimiento: movimiento.data,
                retenciones: retenciones.data,
                documentos: documentos.data,
                loading: false
            });
        }
        
    }
    const [egreso, setEgreso ] = useState(
        {
            encabezado: {
                rowid: '',
                idCo: '',
                tipoDoc: '',
                numeroDoc: '',
                fechaDoc: new Date(),
                valor: 0
            },
            retenciones: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0,
                totalBase: 0
            },
            movimiento: {
                detalles: [],
                totalDebito: 0,
                totalCredito: 0
            },
            documentos: [],
            loading: false,
            error: {},
            reg: 0
        }
    );

    const generarEgresoDetallePdf = async () => {

        let data = {
            empresa: estado.empresa,
            proveedor: estado.proveedor,
            encabezado: egreso.encabezado,
            retenciones: egreso.retenciones,
            movimiento: egreso.movimiento,
            documentos: egreso.documentos
        }

        console.log(data);

        props.history.push({ pathname: '/portal/egreso-pdf', state: { egreso: data } });
    }

    const documentoDetallePDF = async (doc) => {
        
        const retenciones = await httpGet('/api/v1/egresos/documento/retenciones/' + doc.factRowid);
        const detalles = await httpGet('/api/v1/egresos/documento/detalle/' + doc.factRowid);

        if((detalles.data !== null) && (detalles.data !== undefined)){

            const data = {
                empresa: estado.empresa,
                proveedor: JSON.parse(sessionStorage.getItem('proveedor')),
                encabezado: doc,
                documento: {
                    detalles: detalles.data,
                    retenciones: retenciones.data
                }
            }
            
            props.history.push({ pathname: '/portal/nota-pdf', state: {documento: data} });
        }

        
        
    }

    return(
        <div className='page-container'>
            <Title>Detalle de egreso</Title>
            <div className='page-body'>
                <SubTitle>Empresa: { estado.empresa.razonSocial }</SubTitle>
                <SubTitle>Proveedor:</SubTitle>
                <div>
                    <div><span className='info-title'>N.I.T: </span><span>{ estado.proveedor.nit }</span></div>
                    <div><span className='info-title'>Razón social: </span><span>{ estado.proveedor.razonSocial }</span></div>
                </div>
                <br />
                <br />
                <SubTitle>Detalle del egreso</SubTitle>
                {egreso.loading ?
                <div className='loading-spinner'>
                    <Spinner animation="border" variant="primary" />
                </div>
                :
                <>
                <table className='info-table'>
                    <thead>
                        <tr>
                            <th>CO</th>
                            <th>DOCUMENTO</th>
                            <th>FECHA DEL DOCUMENTO</th>
                            {/*<th>CUENTA BANCARIA</th>*/}
                            <th>VALOR DEL DOCUMENTO</th>                                                        
                            <th>DESCARGAR</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>{ egreso.encabezado.idCo }</td>
                            <td>{ egreso.encabezado.tipoDoc + '-' + egreso.encabezado.numeroDoc }</td>
                            <td>{ formatDateMin(new Date(egreso.encabezado.fechaDoc)) }</td>
                            {/*<td>{ egreso.encabezado.cuenta_bancaria }</td>*/}
                            <td>${ formatNumber(egreso.encabezado.valor) }</td>
                            <td><div className='pdf-icon-container' onClick={generarEgresoDetallePdf}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Movimiento contable</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CODIGO CUENTA</th>
                            <th>DESCRIPCION CUENTA</th>
                            <th>CO</th>
                            <th>TERCERO</th>
                            <th>RAZON SOCIAL</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.movimiento.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ detalle.cuentaId }</td>
                                            <td>{ detalle.cuentaDescripcion }</td>
                                            <td>{ detalle.idCo }</td>
                                            <td>{ detalle.nit }</td>
                                            <td>{ detalle.razonSocial }</td>
                                            <td>${ formatNumber(detalle.valorDb) }</td>
                                            <td>${ formatNumber(detalle.valorCr) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td></td>
                            <td>SUMAS IGUALES</td>
                            <td>${ formatNumber(egreso.movimiento.totalDebito) }</td>
                            <td>${ formatNumber(egreso.movimiento.totalCredito) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Retenciones</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CODIGO CUENTA</th>
                            <th>DESCRIPCION CUENTA</th>
                            <th>VALOR RETENCIÓN</th>
                            <th>BASE</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.retenciones.detalles.map(
                                (detalle, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ detalle.cuenta }</td>
                                            <td>{ detalle.cuentaDescripcion }</td>
                                            <td>${ formatNumber(detalle.valorCr - detalle.valorDb) }</td>
                                            <td>${ formatNumber(detalle.valorBase) }</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                        <tr>
                            <td></td>
                            <td></td>
                            <td>TOTAL:</td>
                            <td>${ formatNumber(egreso.retenciones.totalCredito - egreso.retenciones.totalDebito) }</td>
                            <td>${ formatNumber(egreso.retenciones.totalBase) }</td>
                        </tr>
                    </tbody>
                </table>
                <br />
                <br />
                <SubTitle>Documentos</SubTitle>
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CO</th>
                            <th>DOC. CRUCE</th>
                            <th>DETALLE</th>
                            <th>DESCUENTOS</th>
                            <th>RETENCIONES</th>
                            <th>VALOR DESCUENTO P.P.</th>
                            <th>VALOR</th>
                            <th>VER</th>
                            {/*<th>DESCARGAR</th>*/}
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.documentos.map(
                                (documento, index) => {
                                    return(
                                        <tr key={index}>
                                            <td>{ (index + 1) }</td>
                                            <td>{ documento.docIdCoMov + ' ' + documento.docCoDescripcion }</td>
                                            <td>{ documento.docCruTipoDoc + ' ' + documento.docCruNumeroDoc }</td>
                                            <td>{ documento.notas }</td>
                                            <td>${ formatNumber(documento.factDscto) }</td>
                                            <td>${ formatNumber(documento.factValorRetencion) }</td>
                                            <td>${ formatNumber(documento.valorAplicadoPP) }</td>
                                            <td>${ formatNumber(documento.valorPagoAlt) }</td>
                                            <td><div className='view-icon-container'> { documento.docpro === 1 ? <Link to={{ pathname: '/portal/documento-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link> : <Link to={{ pathname: '/portal/documento-fi-detalle', state: { documento: documento } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link> }</div></td>
                                            {/*<td><div className='pdf-icon-container' onClick={() => {documentoDetallePDF(documento)}}><FontAwesomeIcon className='view-icon' icon={faFilePdf} /></div></td>*/}
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                {/*<br />
                <br />
                <table className='info-table doc-table'>
                    <thead>
                        <tr>
                            <th>REG.</th>
                            <th>CENTRO DE OPERACION</th>
                            <th>CUENTA</th>
                            <th>DOCUMENTO CRUCE</th>
                            <th>DEBITOS</th>
                            <th>CREDITOS</th>
                            <th>VER</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            egreso.detalles.map(
                                detalle => {
                                    return(
                                        <tr key={detalle.id}>
                                            <td>{detalle.id}</td>
                                            <td>{ detalle.coDocumento }</td>
                                            <td>{ detalle.idCuenta }</td>
                                            <td>{ detalle.tipoDocumentoProveedor + '-' + detalle.numeroDocumentoProveedor }</td>
                                            <td>${ formatNumber(detalle.debitos) }</td>
                                            <td>${ formatNumber(detalle.creditos) }</td>
                                            <td>{ detalle.idCuenta.substring(0, 2) === '22' && <div className='view-icon-container'><Link to={{ pathname: '/portal/documentos-detalle', state: { detalle: detalle } }}><FontAwesomeIcon className='view-icon' icon={faEye} /></Link></div>}</td>
                                        </tr>
                                    );
                                }
                            )
                        }
                    </tbody>
                </table>
                    */}
                </>
                }
            </div>
        </div>
    );
}

export default EgresoDetalle
